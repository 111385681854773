// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  //BASE_URL: 'https://mdcnapi.webhostingng.org/',
  // BASE_URL: 'http://localhost/mdcn_backend/',
  BASE_URL: 'https://api.housemanship.mdcn.gov.ng/',
 // BASE_URL: 'https://mdcntest.webhostingng.org/',
 // BASE_URL: 'https://api.housemanship.mdcn.gov.ng/', // live
  //BASE_URL: 'https://housemanshipapi.mdcn.gov.ng/', // live

  //https://housemanshipapi.mdcn.gov.ng/
  // BASE_URL: 'http://localhost/mdcn/', // local

  //BASE_URL: 'https://api.uat.housemanship.mdcn.gov.ng/',
  MDCN_API:'https://api.portal.mdcn.gov.ng/get-doctor-status'
//authTokenKey:'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3QiLCJhdWQiOiJodHRwOlwvXC9sb2NhbGhvc3QiLCJpYXQiOjEzNTY5OTk1MjQsIm5iZiI6MTM1NzAwMDAwMCwiZGF0YSI6eyJpZCI6IjEzIiwiZmlyc3RuYW1lIjoiTXVoYW1tYWQgWWVzIiwibGFzdG5hbWUiOiJKYW1hbCIsImVtYWlsIjoibWhvamFtYWxAa3NvbHV0aW9uc25nLmNvbSIsInVzZXJuYW1lIjpudWxsLCJwaG9uZSI6IjA4MDQzNDQ1NTY2Iiwiem9uZSI6IjEiLCJyb2xlIjoiMSJ9fQ.Ov4u7gLC05jnizf14c3dBT0AIU0I1DqloyOysvQl_kY'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
